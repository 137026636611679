import React, { useState } from 'react';
import classNames from 'classnames';

import { INVESTIGATION, SCHEDULE, SIGNAL } from '@tafs/constants/appModes';
import styles from './index.module.css';
import IncidentsPopover from '@tafs/components/common/IncidentsPopover';
import {
  MANUAL_SIGNALS,
  MARKET_MAKER_SIGNALS,
} from '@tafs/constants/signals/signalTypes';
import {
  SIGNALS_LIST_PAGE_MANUAL,
  SIGNALS_LIST_PAGE_MM_INCIDENTS,
  SIGNALS_LIST_PAGE_MOEX_INCIDENTS,
} from '@tafs/constants/routes';
import { getWorkspacePath } from '@tafs/constants/workspaces';
import StatefulLink from '@tafs/components/common/StatefulLink';

const TotalCount = ({
  isSignal,
  duplicatesObj,
  incidentsNew,
  workspace,
  ruleCode,
}) => {
  const [popoverData, setPopoverData] = useState(null);

  const investigationTotal = [
    ...(incidentsNew[INVESTIGATION] || []),
    ...(duplicatesObj[INVESTIGATION] || []),
  ];
  const scheduleTotal = [
    ...(incidentsNew[SCHEDULE] || []),
    ...(duplicatesObj[SCHEDULE] || []),
  ];
  const signalsTotal = [
    ...(incidentsNew[SIGNAL] || []),
    ...(duplicatesObj[SIGNAL] || []),
  ];

  const incidentsTotalLength = investigationTotal.length + scheduleTotal.length;

  const popoverDataObj = {
    INVESTIGATION: investigationTotal,
    SCHEDULE: scheduleTotal,
  };

  const handlePopoverClick = (e) => {
    if (!isSignal)
      setPopoverData({
        popoverOpened: true,
        data: popoverDataObj,
        anchorPosition: { left: e.clientX + 50, top: e.clientY + 10 },
        ws: workspace,
      });
  };

  const getLink = (count) => {
    const getUrl = () => {
      return ruleCode === MARKET_MAKER_SIGNALS
        ? SIGNALS_LIST_PAGE_MM_INCIDENTS
        : MANUAL_SIGNALS.includes(ruleCode)
        ? SIGNALS_LIST_PAGE_MANUAL
        : SIGNALS_LIST_PAGE_MOEX_INCIDENTS;
    };

    return count ? (
      <StatefulLink
        pathname={getWorkspacePath(workspace, getUrl())}
        storedObj={{
          idList: signalsTotal,
        }}
      >
        {count}
      </StatefulLink>
    ) : (
      0
    );
  };

  return (
    <>
      <span
        onClick={handlePopoverClick}
        className={classNames({
          [styles.link]:
            incidentsTotalLength > 0 || (isSignal && signalsTotal.length > 0),
        })}
      >
        {isSignal ? getLink(signalsTotal.length) : incidentsTotalLength}
      </span>

      <IncidentsPopover
        incidentsData={popoverData}
        onClose={() => setPopoverData({ ...popoverData, popoverOpened: false })}
      />
    </>
  );
};

export default TotalCount;
