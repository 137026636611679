import gql from 'graphql-tag';
import isEmpty from 'lodash/isEmpty';
import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';
import { getTime, parse, addDays } from 'date-fns';

import { graphql } from '@tafs/services/api';
import { getQueryString, flattenArray } from './utils';
import { addPatternEscapeChars } from '@tafs/utils';

const MAX_EXCEL_ROWS = 65535;

class ServerSideDatasource {
  constructor({ columns, entityType, queryFields, filters, gridOptions }) {
    this.columns = columns;
    this.entityType = entityType;
    this.queryFields = queryFields;
    this.filters = filters || [];
    this.gridOptions = gridOptions;

    this.rows = [];
    this.totalElements = -1;
    this.isDataLoaded = false;
    this.combinedFilters = {};
  }

  getRows(params) {
    this.totalElements = -1;
    this.isDataLoaded = false;

    const currentPage =
      params.parentNode.gridOptionsWrapper.gridApi.paginationProxy.currentPage;
    const columnDefs = params.parentNode.columnController.columnDefs;
    const pageSize = params.request.endRow - params.request.startRow;

    graphql
      .query(
        this.query(params.request, this.queryFields, currentPage, columnDefs)
      )
      .then((response) => {
        this.isDataLoaded = true;
        const data = response.data[this.entityType];
        this.rows = data.content || [];

        if (this.rows.length === 0) {
          params.parentNode.gridOptionsWrapper.gridApi.showNoRowsOverlay();
        }

        params.successCallback(
          this.rows,
          this.totalElements >= 0
            ? this.totalElements
            : this.rows.length < pageSize
            ? this.rows.length
            : undefined
        );
      })
      .catch((err) => {
        console.error(err);
        params.failCallback();
      });

    graphql
      .query(this.queryTotalElements(params.request, columnDefs))
      .then((response) => {
        const data = response.data[this.entityType];

        if (this.totalElements !== data.totalElements) {
          this.totalElements = data.totalElements;

          this.isDataLoaded &&
            params.successCallback(this.rows, this.totalElements);
        }
      })
      .catch((err) => {
        console.error(err);
        params.failCallback();
      });
  }

  getFieldQuery = (fieldName, sortModel) => {
    const fieldSort = sortModel.find((col) => col.colId === fieldName);
    const paths = fieldName.split('.');

    const gqFieldName = paths[paths.length - 1];
    let fieldDef = fieldSort
      ? `${gqFieldName}(OrderBy:${fieldSort.sort.toUpperCase()})`
      : gqFieldName;
    for (let index = paths.length - 2; index >= 0; index--) {
      const path = paths[index];
      fieldDef = { [path]: [fieldDef] };
    }
    return fieldDef;
  };

  getFieldFilter = (name, field, isTimestamp) => {
    const filter = {
      // regex removes agGrid numerical suffix for filters of duplicated fields
      key: name.replace(/(_[0-9]+)$/, ''),
      operator: field.type,
    };

    switch (field.filterType) {
      case 'set':
        if (field.values.length === 0) return { ...filter, operator: 'ISNULL' };
        else
          return { ...filter, value: field.values.join(','), operator: 'IN' };
      case 'number':
        return { ...filter, value: field.filter };
      case 'text':
        switch (filter.operator) {
          case 'equals':
            return { ...filter, value: field.filter, operator: 'equals' };
          case 'notEquals':
            return { ...filter, value: field.filter, operator: 'notEqual' };
          case 'contains':
            return {
              ...filter,
              value: `%${addPatternEscapeChars(field.filter)}%`,
              operator: 'LIKE',
            };
          case 'notContains':
            return {
              ...filter,
              value: `%${addPatternEscapeChars(field.filter)}%`,
              operator: 'NOTLIKE',
            };
          case 'startsWith':
            return {
              ...filter,
              value: `${addPatternEscapeChars(field.filter)}%`,
              operator: 'LIKE',
            };
          case 'endsWith':
            return {
              ...filter,
              value: `%${addPatternEscapeChars(field.filter)}`,
              operator: 'LIKE',
            };
          default:
            return { ...filter, value: field.filter };
        }
      case 'date':
        // workaround with passing isTimestamp flag should be removed as soon as backend team unifies milliseconds and formatted date filters
        if (isTimestamp) {
          const date = parse(field.dateFrom || field.dateTo, 'yyyy-MM-dd', 0);

          if (filter.operator === 'equals') {
            return {
              key: filter.key,
              operator: 'NOTLESSTHAN',
              value: getTime(date),
              combinator: 'AND',
              next: {
                key: filter.key,
                operator: 'lessThan',
                value: getTime(addDays(date, 1)),
              },
            };
          } else {
            return { ...filter, value: getTime(date) };
          }
        } else {
          return { ...filter, value: field.dateFrom || field.dateTo };
        }
      default:
        break;
    }
  };

  getFilters = (filterModel, columnDefs) => {
    if (!isEmpty(filterModel) || !isEmpty(this.filters)) {
      const andFilters = Object.keys(filterModel).map((name) => {
        const fieldFilter = filterModel[name];
        const isTimestamp = columnDefs.find(
          (def) => def.field === name
        )?.isTimestamp;

        if (fieldFilter.operator) {
          return Object.keys(fieldFilter)
            .filter((key) => key !== 'operator')
            .reduce((result, item) => {
              if (isEmpty(result)) {
                return this.getFieldFilter(
                  name,
                  fieldFilter[item],
                  isTimestamp
                );
              } else {
                return {
                  ...this.getFieldFilter(name, fieldFilter[item], isTimestamp),
                  combinator: fieldFilter.operator,
                  next: result,
                };
              }
            }, {});
        } else {
          return this.getFieldFilter(name, fieldFilter, isTimestamp);
        }
      });
      return [...andFilters, ...this.filters].reduce((result, item) => {
        if (isEmpty(result)) {
          return item;
        } else {
          if (!item.next) {
            return {
              ...item,
              combinator: 'AND',
              next: result,
            };
          } else {
            return {
              ...item,
              next: { ...item.next, combinator: 'AND', next: result },
            };
          }
        }
      }, {});
    } else {
      return undefined;
    }
  };

  addQueryField = (fieldName, sortModel, res, mergeFields) => {
    const fieldSort = sortModel.find((col) => col.colId === fieldName);
    const paths = fieldName.split('.');

    const gqFieldName = paths[paths.length - 1];
    let fieldDef = fieldSort
      ? `${gqFieldName}(OrderBy:${fieldSort.sort.toUpperCase()})`
      : gqFieldName;

    for (let index = paths.length - 2; index >= 0; index--) {
      const path = paths[index];
      fieldDef = { [path]: [fieldDef] };
    }

    const customizer = (objValue, srcValue, key, object, source, stack) => {
      if (isArray(objValue)) {
        if (typeof srcValue[0] === 'object') {
          const itemKey = Object.keys(srcValue[0])[0];
          const sourceParent = objValue.find(
            (parent) =>
              typeof parent === 'object' && Object.keys(parent)[0] === itemKey
          );
          if (sourceParent) {
            sourceParent[itemKey] = sourceParent[itemKey].concat(
              srcValue[0][itemKey]
            );
            return objValue;
          }
        }
        return objValue.concat(srcValue);
      }
    };

    if (typeof fieldDef === 'string' || !mergeFields) res.push(fieldDef);
    else return mergeWith(res, [fieldDef], customizer);
  };

  query = (request, queryFields, currentPage, columnDefs) => {
    const fields = [];
    queryFields
      //sort fields to add simple values at end, because at some bug in addQueryField func
      .sort((i1, i2) =>
        i1.field.split('.').length < i2.field.split('.').length ? 1 : -1
      )
      .forEach((item) => {
        this.addQueryField(item.field, request.sortModel, fields, true);
      });

    const filter = this.getFilters(request.filterModel, columnDefs);
    this.combinedFilters = filter;

    const gqQuery = { query: '', variables: {}, fetchPolicy: 'no-cache' };

    gqQuery.query = gql`
      query($page: Int!, $size: Int!, ${filter ? '$filter: qfilter' : ''}) {
        ${this.entityType}(paginator: { page: $page, size: $size }, ${
      filter ? 'qfilter: $filter' : ''
    } ) { 
          content {
            ${getQueryString(fields) || 'EMPTY'}
        }
      }
    }
    `;
    gqQuery.variables = {
      page: currentPage + 1,
      size: request.endRow - request.startRow,
    };

    if (filter) {
      gqQuery.variables.filter = filter;
    }

    return gqQuery;
  };

  queryTotalElements = (request, columnDefs) => {
    const filter = this.getFilters(request.filterModel, columnDefs);
    const gqQuery = { query: '', variables: {}, fetchPolicy: 'no-cache' };

    gqQuery.query = gql`
      query ${filter ? '($filter: qfilter)' : ''} {
        ${this.entityType} ${filter ? '(qfilter: $filter)' : ''}{
          totalElements
      }
    }
    `;

    if (filter) {
      gqQuery.variables.filter = filter;
    }

    return gqQuery;
  };

  getQueryForExport = (initialColumnDefs) => {
    if (this.totalElements > MAX_EXCEL_ROWS) {
      throw Object.assign(
        new Error(
          'The exported document exceeds Excel row limit. Please apply filters to reduce the number of rows exported.'
        ),
        { count: MAX_EXCEL_ROWS }
      );
    }

    const sortModel = this.gridOptions.api.getSortModel();
    const filterModel = this.gridOptions.api.getFilterModel();
    const colState = this.gridOptions.columnApi.getColumnState();
    const gridColumnDefs = this.gridOptions.columnDefs;
    const flatInitialColumnDefs = flattenArray(initialColumnDefs);

    const sortedFields = colState.reduce((fields, column) => {
      const { colId, hide } = column;

      const isVisible = flatInitialColumnDefs.some(
        (col) => colId === col?.field && !col.ignore && !col.ignoreOnExport
      );
      const queryField = this.queryFields.find((col) => colId === col.field);

      if (isVisible && queryField && !hide) fields.push(queryField);
      return fields;
    }, []);

    const fields = [];
    sortedFields.forEach((item) => {
      this.addQueryField(item.field, sortModel, fields);
    });

    const filter = this.getFilters(filterModel, gridColumnDefs);
    const gqQuery = { query: '', variables: {}, operationName: null };

    if (filter) {
      gqQuery.variables.filter = filter;
      gqQuery.query = `
        query($filter: qfilter) {
          ${this.entityType}(qfilter: $filter) { 
            content {
              ${getQueryString(fields) || 'EMPTY'}
          }
        }
      }`;
    } else {
      gqQuery.query = `
        query {
          ${this.entityType} { 
            content {
              ${getQueryString(fields) || 'EMPTY'}
          }
        }
      }`;
    }
    return gqQuery;
  };
}

export default ServerSideDatasource;
